import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  background-color: #333;
  padding: 1rem;
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin: 0 1rem;
`;

const NavButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Navbar = ({ onLogout }) => {
  return (
    <NavbarContainer>
      <NavList>
        <NavItem>
          <StyledLink to="/home">Home</StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/my-ads">My Ads</StyledLink>
        </NavItem>
        <NavItem>
          <NavButton onClick={onLogout}>Logout</NavButton>
        </NavItem>
      </NavList>
    </NavbarContainer>
  );
};

export default Navbar;
