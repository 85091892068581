import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Asegúrate de importar Navigate
import styled from 'styled-components';
import Register from './Register';
import Login from './Login';
import Home from './Home'; // Componente de ejemplo para una ruta protegida
import MyAds from './MyAds'; // Nuevo componente
import Navbar from './Navbar'; // Nuevo componente

const AppContainer = styled.div`
  font-family: Arial, sans-serif;
  text-align: center;
`;

const Header = styled.h1`
  background-color: #282c34;
  color: white;
  padding: 1rem;
  margin: 0;
`;

const App = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken('');
  };

  return (
    <Router>
      <div>
        <h1>My App</h1>
        {token ? (
          <>
            <Navbar onLogout={handleLogout} />
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/my-ads" element={<MyAds />} />
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login setToken={setToken} />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
};

export default App;
