import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const AdsContainer = styled.div`
  padding: 2rem;
`;

const AdCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  text-align: left;
`;

const AdTitle = styled.h3`
  margin: 0 0 0.5rem;
`;

const AdDescription = styled.p`
  margin: 0 0 1rem;
`;

const FormContainer = styled.div`
  margin: 2rem 0;
`;

const FormField = styled.div`
  margin: 1rem 0;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const MyAds = () => {
  const [ads, setAds] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [message, setMessage] = useState('');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchAds = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${API_BASE_URL}/ads`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setAds(response.data);
    } catch (error) {
      console.error('Error fetching ads:', error);
    }
  };

  useEffect(() => {
    fetchAds();
  }, []);

  const handleCreateAd = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `${API_BASE_URL}/ads`,
        { title, description, price },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setMessage('Ad created successfully!');
      fetchAds();
    } catch (error) {
      setMessage('Error creating ad!');
    }
  };

  return (
    <AdsContainer>
      <h2>My Ads</h2>
      {ads.map((ad) => (
        <AdCard key={ad.id}>
          <AdTitle>{ad.title}</AdTitle>
          <AdDescription>{ad.description}</AdDescription>
          <p>Price: ${ad.price}</p>
        </AdCard>
      ))}
      <FormContainer>
        <h2>Create New Ad</h2>
        <form onSubmit={handleCreateAd}>
          <FormField>
            <Label>Title:</Label>
            <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
          </FormField>
          <FormField>
            <Label>Description:</Label>
            <Input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
          </FormField>
          <FormField>
            <Label>Price:</Label>
            <Input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
          </FormField>
          <Button type="submit">Create Ad</Button>
        </form>
        {message && <p>{message}</p>}
      </FormContainer>
    </AdsContainer>
  );
};

export default MyAds;
